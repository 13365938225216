import { alpha } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import FolderIcon from 'src/assets/icons/folder-icon.svg?react';
import FolderOpenIcon from 'src/assets/icons/folder-open-icon.svg?react';
import baseTheme from '../baseTheme';

const { palette, spacing, typography } = baseTheme;

export default {
  MuiTreeView: {
    defaultProps: {
      disableSelection: true,
      collapseIcon: <SvgIcon component={FolderOpenIcon} />,
      expandIcon: <SvgIcon component={FolderIcon} />,
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      root: {
        color: palette.text.secondary,
        minHeight: spacing(2),
        padding: spacing(0.4, 0),
        ...typography.caption,
      },
      label: {
        ...typography.caption,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      content: {
        '.MuiButtonBase-root': {
          maxHeight: spacing(1.8),
          padding: 0,
        },
      },
      groupTransition: {
        marginLeft: spacing(1.5),
        paddingLeft: spacing(1.8),
        borderLeft: `1px dashed ${alpha(palette.text.primary, 0.4)}`,
      },
    },
  },
};
