import { DataGroup } from '../dicts';

export enum DataRoomItemType {
  folder = 'FOLDER',
  loanAppDoc = 'LOAN_APPLICATION_DOCUMENT',
}

export enum DataRoomArchiveStatus {
  Submitted = 'SUBMITTED',
  Done = 'DONE',
}

export enum DataRoomItemFields {
  Name = 'name',
  Path = 'path',
}

export enum DataRoomUploadFilesFields {
  DocumentFiles = 'documentFiles',
}

export type DataRoomItem = {
  id: string;
  itemType: DataRoomItemType;
  link: string;
  name: string;
  children?: DataRoomItem[];
};

export type DataRoomListParams = {
  group: DataGroup;
  id?: number;
};

export type DataRoomInitArchiveParams = {
  group: DataGroup;
  id?: number;
  items?: string | string[];
};

export type DataRoomArchStatusParams = {
  group: DataGroup;
  id?: number;
  archiveId?: number;
};

export type DataRoomCreateFolderParams = {
  group: DataGroup;
  loanApplicationId: number;
  parentId: string;
  name: string;
};

export type DataRoomUploadFilesParams = {
  group: DataGroup;
  loanApplicationId: number;
  parentId: string;
  documentFiles?: FileList | null;
};

export type DataRoomDeleteFileParams = {
  group: DataGroup;
  loanApplicationId: number;
  documentId: string;
};

export type DataRoomArchiveRes = {
  id: number;
  statusCode: DataRoomArchiveStatus;
  createdDate: string;
  createdBy: string;
  readiness: number;
};
