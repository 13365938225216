import { DataGroup } from '../dicts';

export enum OfferInitiatorType {
  Borrower = 'BORROWER',
  Creditor = 'CREDITOR',
}

export enum OfferVerificationStatus {
  UnderReview = 'UNDER_REVIEW',
  Verified = 'VERIFIED',
}

export enum OfferAcceptanceStatus {
  UnderReview = 'UNDER_REVIEW',
  CounterOffered = 'COUNTER_OFFERED',
  Accepted = 'ACCEPTED',
}

export type OfferValue = {
  id: string;
  offerKey: string;
  offerKeyCode: string;
  offerValue: string;
  parentValue?: string;
};

export type OfferItem = {
  id: number;
  parentId?: number;
  loanApplicationId: number;
  opportunityId?: number;
  initiatorType: OfferInitiatorType;
  borrowerCompanyFullName?: string;
  creditorCompanyFullName?: string;
  verificationStatusCode: OfferVerificationStatus;
  acceptanceStatusCode: OfferAcceptanceStatus;
  values: OfferValue[];
  parent?: OfferItem;
};

export enum MakeOfferFormField {
  Values = 'values',
  OfferKey = 'offerKey',
  OfferKeyCode = 'offerKeyCode',
  OfferValue = 'offerValue',
}

export type OffersTermSheet = {
  activeOffers: OfferItem[];
};

export type OfferValuesFormParams = {
  values: OfferValue[];
};

export type OfferBaseUrlParams = {
  group: DataGroup;
  id: number;
};

export type OfferUrlParams = OfferBaseUrlParams & {
  opportunityId?: number;
  termSheetId?: number;
};

export type OfferReqParams = OfferUrlParams & OfferValuesFormParams;
