import { alpha } from '@mui/material/styles';
import baseTheme from '../baseTheme';

const { breakpoints, palette, spacing, typography } = baseTheme;

export default {
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: {
        '.MuiBackdrop-root': {
          backgroundColor: alpha(palette.common.black, 0.2),
          backdropFilter: 'blur(5px)',
        },
      },
      paper: {
        borderRadius: spacing(1.2),
      },
      paperFullScreen: {
        borderRadius: 0,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        alignItems: 'initial',
        gap: spacing(2.5),
        padding: spacing(3.2),
        paddingTop: spacing(2.5),
        [breakpoints.down('sm')]: {
          flexDirection: 'column-reverse',
          '>:not(:first-of-type)': {
            margin: 0,
          },
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(2.5),
        padding: spacing(0, 3.2),
        height: '100%',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        ...typography.h4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: spacing(3.2),
      },
    },
  },
};
