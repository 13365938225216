import * as Yup from 'yup';
import { MakeOfferFormField } from 'src/entities/offers';
import { getValidationArrSchema } from 'src/helpers';

export const offerValuesRowValidation = {
  [MakeOfferFormField.OfferKey]: Yup.string().required('Please enter key'),
  [MakeOfferFormField.OfferValue]: Yup.string().required('Please enter Value'),
};

export const offerFormValidation = {
  [MakeOfferFormField.Values]: getValidationArrSchema(offerValuesRowValidation),
};
