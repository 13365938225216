import React from 'react';
import { useAcceptOfferMutation } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import ModalDialog, { ModalCloseHandler } from '../ModalDialog';
import { AlertType, useAlert } from '../AlertsProvider';

type Props = {
  group: DataGroup;
  id: number;
  termSheetId: number;
  open: boolean;
  onClose: ModalCloseHandler;
};

function TermSheetAcceptOfferModal({
  group,
  id,
  termSheetId,
  open,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const [acceptOffer, { isLoading }] = useAcceptOfferMutation();

  const onAcceptOffer = () =>
    acceptOffer({ group, id, termSheetId })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'The offer has been accepted',
        });
        onClose();
      });

  return (
    <ModalDialog
      onConfirm={onAcceptOffer}
      open={open}
      onClose={onClose}
      title="Accept Offer"
      confirmBtnText="Accept"
      showConfirmBtn
      confirmBtnLoading={isLoading}
    >
      Are you sure you want to accept the offer?
    </ModalDialog>
  );
}

export default TermSheetAcceptOfferModal;
