import React, { ForwardedRef, forwardRef, ReactNode, useState } from 'react';
import { DocFormat, DocTypeCode } from 'src/entities/documents';
import { DEFAULT_DOC_ITEMS_LIMIT, DEFAULT_DOC_SIZE } from './constants';
import { DocInputValue } from './types';
import FileInput from '../FileInput';
import DataRoomDocUploadInputListItem from './DataRoomDocUploadInputListItem';

export type DataRoomDocUploadInputProps<TMultiple extends boolean> = {
  name?: string;
  value?: DocInputValue<TMultiple>;
  label?: ReactNode;
  description?: string;
  docType: DocTypeCode;
  error?: boolean;
  helperText?: ReactNode;
  multiple?: TMultiple;
  required?: boolean;
  onChange?: (value: File[] | null) => void;
  allowedFormats?: DocFormat[];
  maxItems?: number;
  maxSize?: number;
  webkitdirectory?: string;
};

function DataRoomDocUploadInput<TMultiple extends boolean>(
  {
    value,
    docType,
    multiple,
    onChange,
    allowedFormats = Object.values(DocFormat),
    maxItems = DEFAULT_DOC_ITEMS_LIMIT,
    maxSize = DEFAULT_DOC_SIZE,
    ...rest
  }: DataRoomDocUploadInputProps<TMultiple>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [currentFiles, setCurrentFiles] = useState<File | File[] | undefined>(
    [],
  );

  const itemsLimit = multiple ? maxItems : 1;
  const canUpload =
    !currentFiles ||
    (Array.isArray(currentFiles) && currentFiles.length < itemsLimit);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    const filesArray = Array.from(files as ArrayLike<File>);
    setCurrentFiles(filesArray);
    if (onChange) {
      onChange(filesArray);
    }
  };

  const handleDelete = (documentName?: string) => {
    // remove files
    let files: File | File[] | undefined;
    if (Array.isArray(currentFiles)) {
      files = currentFiles.filter((file) => file.name !== documentName);
    } else {
      files = [];
    }
    setCurrentFiles(files);
    if (onChange) {
      onChange(files);
    }
  };

  return (
    <FileInput
      ref={ref}
      value={currentFiles}
      canUpload={canUpload}
      allowedFormats={allowedFormats}
      maxSize={maxSize}
      maxItems={itemsLimit}
      multiple={multiple}
      onChange={handleChange}
      onDelete={handleDelete}
      listItemComponent={DataRoomDocUploadInputListItem}
      {...rest}
    />
  );
}

export default forwardRef(DataRoomDocUploadInput);
