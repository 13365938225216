import TextField from '@mui/material/TextField';
import { MakeOfferFormField, OfferValue } from 'src/entities/offers';
import ResponsiveStack from '../ResponsiveStack';
import FormControl from '../FormControl';
import { FormArrayRowHead } from '../FormArray';

type Props = {
  path: string;
  values?: OfferValue;
  onRemoveRow: () => void;
  canRemoveRow?: boolean;
};

function TermSheetFormRow({ path, canRemoveRow, values, ...rest }: Props) {
  return (
    <>
      <FormArrayRowHead
        canRemoveRow={canRemoveRow && !values?.parentValue}
        {...rest}
      />
      <ResponsiveStack>
        <FormControl
          name={`${path}.${MakeOfferFormField.OfferKey}`}
          label="Key"
          component={TextField}
        />
        <FormControl
          name={`${path}.${MakeOfferFormField.OfferValue}`}
          label="Value"
          component={TextField}
          multiline
        />
      </ResponsiveStack>
    </>
  );
}

export default TermSheetFormRow;
