import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import { generatePath, mapObjToFormData } from 'src/utils';
import {
  DATA_ROOM_ARCH_INIT,
  DATA_ROOM_ARCH_STATUS,
  DATA_ROOM_FOLDERS,
  DATA_ROOM_GET_LIST,
  DATA_ROOM_STORE_API_KEY,
  DATA_ROOM_TAG,
  DATA_ROOM_UPLOAD_FILES,
  DATA_ROOM_DELETE_FILE,
} from './constants';
import {
  DataRoomItem,
  DataRoomArchiveRes,
  DataRoomInitArchiveParams,
  DataRoomArchStatusParams,
  DataRoomListParams,
  DataRoomCreateFolderParams,
  DataRoomUploadFilesParams,
  DataRoomDeleteFileParams,
} from './types';
import { DocFileMeta } from '../documents';

export const dataRoomApi = createApi({
  reducerPath: DATA_ROOM_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa }),
  refetchOnMountOrArgChange: true,
  tagTypes: [DATA_ROOM_TAG],
  endpoints: ({ mutation, query }) => ({
    getDataRoomlist: query<DataRoomItem[], DataRoomListParams>({
      query: (params) => ({
        method: ReqMethod.Get,
        url: generatePath(DATA_ROOM_GET_LIST, params),
      }),
      providesTags: [DATA_ROOM_TAG],
      transformResponse: ({ data }: Response<DataRoomItem[]>) => data,
    }),
    getArchiveStatus: query<DataRoomArchiveRes, DataRoomArchStatusParams>({
      query: (params) => ({
        method: ReqMethod.Get,
        url: generatePath(DATA_ROOM_ARCH_STATUS, params),
      }),
    }),
    initArchive: query<DataRoomArchiveRes, DataRoomInitArchiveParams>({
      query: ({ items, ...params }) => ({
        method: ReqMethod.Post,
        url: generatePath(DATA_ROOM_ARCH_INIT, params),
        body: mapObjToFormData({ items }),
      }),
    }),
    createDataRoomFolder: mutation<void, DataRoomCreateFolderParams>({
      query: (body) => ({
        method: ReqMethod.Post,
        url: generatePath(DATA_ROOM_FOLDERS, body),
        body,
      }),
      invalidatesTags: [DATA_ROOM_TAG],
    }),
    uploadDataRoomDocs: mutation<DocFileMeta[], DataRoomUploadFilesParams>({
      query: ({ parentId, documentFiles, ...params }) => ({
        url: generatePath(DATA_ROOM_UPLOAD_FILES, params),
        method: ReqMethod.Post,
        body: mapObjToFormData({ documentFiles, parentId }),
      }),
      invalidatesTags: [DATA_ROOM_TAG],
    }),
    deleteDataRoomDoc: mutation<void, DataRoomDeleteFileParams>({
      query: ({ ...params }) => ({
        url: generatePath(DATA_ROOM_DELETE_FILE, params),
        method: ReqMethod.Delete,
      }),
      invalidatesTags: [DATA_ROOM_TAG],
    }),
  }),
});

export const {
  useGetDataRoomlistQuery,
  useGetArchiveStatusQuery,
  useInitArchiveQuery,
  useCreateDataRoomFolderMutation,
  useUploadDataRoomDocsMutation,
  useDeleteDataRoomDocMutation,
} = dataRoomApi;
