import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import TimeIcon from 'src/assets/icons/time-icon.svg?react';
import { generatePath } from 'src/utils';
import {
  FUNDING_OPPS_DOWNLOAD_NDA,
  FundingOppItem,
  FundingOppNDAStatus,
} from 'src/entities/fundingOpps';
import FundingOppDetailsUploadNda from './FundingOppDetailsUploadNda';

function FundingOppDetails({
  id,
  ndaStatusCode,
  ndaFromCreditorDocId,
  fullDetailsAvailable,
}: FundingOppItem) {
  const ndaIsPending = !!ndaFromCreditorDocId && !fullDetailsAvailable;

  return (
    <>
      {!ndaFromCreditorDocId && !fullDetailsAvailable && (
        <FundingOppDetailsUploadNda id={id} />
      )}
      {ndaIsPending && (
        <>
          <Stack component={Paper} gap={1} p={2}>
            <Typography variant="subtitle1">
              <SvgIcon component={TimeIcon} sx={{ mr: 1 }} />
              Your NDA is under review
            </Typography>
            Once it&apos;s accepted, you will be able to see all the blocked
            details.
          </Stack>
          <Typography color="text.secondary" variant="body2" mt={2}>
            You have uploaded the NDA.&nbsp;
            <Link href={generatePath(FUNDING_OPPS_DOWNLOAD_NDA, { id })}>
              Download file
            </Link>
          </Typography>
        </>
      )}
      {ndaStatusCode === FundingOppNDAStatus.Signed && (
        <Typography color="text.secondary" variant="body2">
          Your NDA has been accepted and signed by Monivolt.&nbsp;
          <Link href={generatePath(FUNDING_OPPS_DOWNLOAD_NDA, { id })}>
            Download file
          </Link>
        </Typography>
      )}
    </>
  );
}

export default FundingOppDetails;
