import React from 'react';
import {
  useUpdateOfferMutation,
  OfferValuesFormParams,
  OfferValue,
} from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import { ModalCloseHandler } from '../ModalDialog';
import { AlertType, useAlert } from '../AlertsProvider';
import TermSheetFormModal from './TermSheetFormModal';

type Props = {
  group: DataGroup;
  id: number;
  termSheetId: number;
  open: boolean;
  onClose: ModalCloseHandler;
  values?: OfferValue[];
};

function TermSheetEditModal({
  group,
  id,
  termSheetId,
  onClose,
  ...rest
}: Props) {
  const { showAlert } = useAlert();
  const [updateOffer, { isLoading }] = useUpdateOfferMutation();

  const onMakeOffer = async ({ values }: OfferValuesFormParams) =>
    updateOffer({ group, id, termSheetId, values })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Term sheet has been updated',
        });
        onClose();
      });

  return (
    <TermSheetFormModal
      onClose={onClose}
      onSubmit={onMakeOffer}
      loading={isLoading}
      title="Edit Term Sheet"
      {...rest}
    />
  );
}

export default TermSheetEditModal;
