import React from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import PlusIcon from 'src/assets/icons/plus-icon.svg?react';

type Props = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  showIcon?: boolean;
};

const style = {
  alignSelf: 'flex-start',
};

function FormArrayRowAddBtn({ label, showIcon = true, ...rest }: Props) {
  return (
    <Button
      variant="text"
      startIcon={showIcon && <SvgIcon component={PlusIcon} />}
      sx={style}
      {...rest}
    >
      {label}
    </Button>
  );
}

export default FormArrayRowAddBtn;
