import React, { ForwardedRef, forwardRef } from 'react';
import DataRoomDocUploadInput, {
  DataRoomDocUploadInputProps,
} from './DataRoomDocUploadInput';

function DataRoomFolderUploadInput<TMultiple extends boolean>(
  props: DataRoomDocUploadInputProps<TMultiple>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <DataRoomDocUploadInput
      multiple
      ref={ref}
      webkitdirectory="true"
      {...props}
    />
  );
}

export default forwardRef(DataRoomFolderUploadInput);
