import React, { Fragment, ReactNode } from 'react';

export const arrayFromNumber = (length: number): number[] =>
  Array.from(Array(length).keys());

export const arrayFromNonEmpty = <TItem,>(value?: TItem | TItem[]) =>
  (Array.isArray(value) || !value ? value : [value]) as TItem[] | undefined;

export const arrayToObject = <TItem extends object>(
  array?: TItem[],
  key = 'id',
) =>
  Array.isArray(array) &&
  Object.fromEntries(array?.map((item) => [item[key as keyof TItem], item]));

export const compactArray = <TItem,>(
  array: (TItem | false | null | undefined)[],
) => array.filter((item) => item) as TItem[];

export const flattenArray = <TItem,>(arrays: TItem[][]): TItem[] =>
  arrays.reduce((acc, item) => [...acc, ...item], []);

export const elementsArrFromNumber = (
  element: ReactNode,
  length: number,
): ReactNode =>
  arrayFromNumber(length).map((index) => (
    <Fragment key={index}>{element}</Fragment>
  ));

export const splitArrayBy = <TItem,>(
  array: TItem[],
  isValid: (item: TItem) => boolean | undefined,
) =>
  array.reduce(
    ([pass, fail]: TItem[][], elem: TItem) =>
      isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]],
    [[], []],
  );
