import React from 'react';
import { useForm, getDefaultFormValues } from 'src/helpers';
import FormArray, { FormArrayRowAddBtn } from 'src/components/FormArray';
import {
  LoanAppFormContactPersons,
  LoanAppFormField,
  useLoanAppState,
} from 'src/entities/loanApps';
import { StepComponentProps } from 'src/components/Stepper';
import LoanAppFormWrapper from './LoanAppFormWrapper';
import LoanAppContactPersonRow from './LoanAppContactPersonRow';
import { contactPersonsValidation, contactPersonValidation } from './formData';

function LoanAppContactPersons(props: StepComponentProps) {
  const formProps = useForm<LoanAppFormContactPersons>({
    defaultValues: useLoanAppState(),
    validation: contactPersonsValidation,
  });

  return (
    <LoanAppFormWrapper stepId="contact-persons" {...props} {...formProps}>
      <FormArray
        name={LoanAppFormField.ContactPersons}
        rowDefaultValues={getDefaultFormValues(contactPersonValidation)}
        rowComponent={LoanAppContactPersonRow}
        addRowComponent={FormArrayRowAddBtn}
        addRowProps={{ label: 'Additional contact persons' }}
      />
    </LoanAppFormWrapper>
  );
}

export default LoanAppContactPersons;
