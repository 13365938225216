import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { OfferItem } from 'src/entities/offers';
import TermSheetRow from './TermSheetRow';
import ListSkeleton from '../ListSkeleton';
import { TermSheetContext } from './TermSheetContext';
import { DataListWrapper } from '../DataList';
import { DataGroup } from '../../entities/dicts';

type Props = {
  group: DataGroup;
  items?: OfferItem[];
  loading?: boolean;
};

function TermSheetItems({ group, items, loading }: Props) {
  const { showCreateOfferModal } = useContext(TermSheetContext);

  if (loading || !items?.length) {
    return (
      <ListSkeleton loading={loading}>
        No offers yet
        {group === DataGroup.loanApps && (
          <Button size="small" onClick={() => showCreateOfferModal()}>
            Create term sheet
          </Button>
        )}
      </ListSkeleton>
    );
  }

  return (
    <DataListWrapper showPaging={false}>
      {items?.map((item) => (
        <TermSheetRow key={item.id} group={group} item={item} />
      ))}
    </DataListWrapper>
  );
}

export default TermSheetItems;
