import React, { ReactNode } from 'react';
import {
  SimpleTreeView,
  SimpleTreeViewProps,
} from '@mui/x-tree-view/SimpleTreeView';
import ListSkeleton from 'src/components/ListSkeleton';
import TreeViewList from './TreeViewList';
import { TreeItemProps, TreeViewItem } from './types';

type Props<T extends TreeViewItem> = SimpleTreeViewProps<false> & {
  data?: T[];
  loading?: boolean;
  itemProps?: TreeItemProps<T>;
  noDataContent?: ReactNode;
};

function TreeView<T extends TreeViewItem>({
  data,
  itemProps,
  loading,
  noDataContent,
  ...rest
}: Props<T>) {
  const noData = !data?.length;

  if (loading || noData) {
    return <ListSkeleton loading={loading}>{noDataContent}</ListSkeleton>;
  }
  return (
    <SimpleTreeView {...rest}>
      <TreeViewList data={data} itemProps={itemProps} />
    </SimpleTreeView>
  );
}

export default TreeView;
