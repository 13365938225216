import { Response } from 'src/services';
import { arrayToObject } from 'src/utils';
import {
  MakeOfferFormField,
  OfferAcceptanceStatus,
  OfferInitiatorType,
  OfferItem,
  OffersTermSheet,
  OfferValue,
} from './types';
import { DataGroup } from '../dicts';

const mergeOfferParentValues = (
  values: OfferValue[],
  parentValues?: OfferValue[],
) => {
  const parentValuesMap = arrayToObject(
    parentValues,
    MakeOfferFormField.OfferKeyCode,
  );
  return values?.map((value) => ({
    ...value,
    parentValue: parentValuesMap[value.offerKeyCode]?.offerValue,
  }));
};

export const mapOffersData = ({ data }: Response<OffersTermSheet>) =>
  data?.activeOffers.map(({ values, parent, ...offer }) => ({
    ...offer,
    values: mergeOfferParentValues(values, parent?.values),
  }));

export const getMakeOfferReqValues = (values: OfferValue[]) =>
  values.map(({ id, offerKey, offerValue }) => ({
    key: offerKey,
    value: offerValue,
    id,
  }));

export const isMyselfInitiator = (
  group: DataGroup,
  initiatorType: OfferInitiatorType,
) => {
  const isLoanAppsGroup = group === DataGroup.loanApps;
  const isFundingOppsGroup = group === DataGroup.fundingOpps;
  const isBorrower = initiatorType === OfferInitiatorType.Borrower;
  const isCreditor = initiatorType === OfferInitiatorType.Creditor;

  return (isBorrower && isLoanAppsGroup) || (isCreditor && isFundingOppsGroup);
};

export const getAllowedOfferActions = (
  group: DataGroup,
  { acceptanceStatusCode, initiatorType, parentId }: OfferItem,
) => {
  const isSelfInitiator = isMyselfInitiator(group, initiatorType);
  const isUnderReview =
    acceptanceStatusCode === OfferAcceptanceStatus.UnderReview;

  return {
    canDelete: parentId && isSelfInitiator && isUnderReview,
    canEdit: isSelfInitiator && isUnderReview,
    canRespond: !isSelfInitiator && isUnderReview,
  };
};

export const getOfferDisplayName = (
  group: DataGroup,
  { borrowerCompanyFullName, creditorCompanyFullName, parentId }: OfferItem,
) => {
  const { name, description } =
    group === DataGroup.loanApps
      ? { name: creditorCompanyFullName, description: 'Creaditor Company Name' }
      : { name: borrowerCompanyFullName, description: 'Borrower Company Name' };

  return {
    primary: parentId ? name : 'Initial offer',
    secondary: parentId && description,
  };
};
