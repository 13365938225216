import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SvgIcon from '@mui/material/SvgIcon';
import MailIcon from 'src/assets/icons/mail-icon.svg?react';
import FormControl from 'src/components/FormControl';
import ResponsiveStack from 'src/components/ResponsiveStack';
import { CreditorAppFormField } from 'src/entities/creditorApp';
import { FormArrayRowHead } from 'src/components/FormArray';

type Props = {
  path: string;
  onRemoveRow: () => void;
  canRemoveRow?: boolean;
};

function CreditorAppContactPersonRow({ path, ...rest }: Props) {
  return (
    <>
      <FormArrayRowHead title="General info" {...rest} />
      <ResponsiveStack>
        <FormControl
          data-testid="name-input"
          name={`${path}.${CreditorAppFormField.Name}`}
          label="First Name"
          component={TextField}
        />
        <FormControl
          data-testid="surname-input"
          name={`${path}.${CreditorAppFormField.Surname}`}
          label="Last Name"
          component={TextField}
        />
      </ResponsiveStack>
      <FormControl
        data-testid="person-rel-type-input"
        name={`${path}.${CreditorAppFormField.PersonRelType}`}
        label="Relationship to the Company"
        component={TextField}
      />
      <ResponsiveStack>
        <FormControl
          data-testid="phone-number-input"
          name={`${path}.${CreditorAppFormField.PhoneNumber}`}
          label="Contact Telephone Number"
          placeholder="+1 (555) 000-0000"
          component={TextField}
        />
        <FormControl
          data-testid="email-input"
          name={`${path}.${CreditorAppFormField.Email}`}
          label="Email Address for Correspondence"
          component={TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon component={MailIcon} />
              </InputAdornment>
            ),
          }}
        />
      </ResponsiveStack>
    </>
  );
}

export default CreditorAppContactPersonRow;
