import React, { ComponentType, useEffect } from 'react';
import {
  Control,
  FieldArray,
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { DEFAULT_FORM_ARRAY_MAX_ROWS } from './constants';
import { AddRowComponentProps, RowComponentProps } from './types';

type Props<TFieldValues extends FieldValues, TAddRowProps> = {
  control?: Control<TFieldValues>;
  name: FieldArrayPath<TFieldValues>;
  maxRows?: number;
  rowDefaultValues?: FieldArray<TFieldValues>;
  rowComponent: ComponentType<RowComponentProps<TFieldValues>>;
  addRowComponent: ComponentType<AddRowComponentProps<TAddRowProps>>;
  addRowProps?: TAddRowProps;
};

type AddRowBaseProps = {
  label: string;
};

function FormArray<
  TFieldValues extends FieldValues,
  TAddRowProps = AddRowBaseProps,
>({
  name,
  control,
  addRowProps,
  addRowComponent: AddRowComponent,
  rowComponent: RowComponent,
  maxRows = DEFAULT_FORM_ARRAY_MAX_ROWS,
  rowDefaultValues = {} as FieldArray<TFieldValues>,
}: Props<TFieldValues, TAddRowProps>) {
  const context = useFormContext<TFieldValues>();
  const { fields, append, update, remove } = useFieldArray({
    name,
    control: control || context.control,
  });
  const { length } = fields;

  useEffect(() => {
    if (!length) {
      update(0, rowDefaultValues);
    }
  }, [length, update, rowDefaultValues]);

  return (
    <>
      {fields.map((values, index) => (
        <RowComponent
          key={values.id}
          control={control}
          values={values}
          path={`${name}.${index}`}
          canRemoveRow={length > 1}
          onRemoveRow={() => remove(index)}
        />
      ))}
      <AddRowComponent
        onClick={() => append(rowDefaultValues)}
        disabled={length >= maxRows}
        {...(addRowProps as TAddRowProps)}
      />
    </>
  );
}

export default FormArray;
