import { configureStore } from '@reduxjs/toolkit';
import {
  authApi,
  authLogoutCleaner,
  authStateSaver,
  authSlice,
} from 'src/entities/auth';
import {
  creditorAppApi,
  creditorAppSlice,
  creditorAppStateSaver,
} from 'src/entities/creditorApp';
import { countriesApi } from 'src/entities/countries';
import { customerApi } from 'src/entities/customer';
import { dataRoomApi } from 'src/entities/dataRoom';
import { docsApi } from 'src/entities/documents';
import { fundingOppsApi } from 'src/entities/fundingOpps';
import {
  loanAppsApi,
  loanAppSlice,
  loanAppStateSaver,
} from 'src/entities/loanApps';
import { offersApi } from 'src/entities/offers';

export default configureStore({
  devTools: import.meta.env.NODE_ENV === 'development',
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [creditorAppApi.reducerPath]: creditorAppApi.reducer,
    [creditorAppSlice.name]: creditorAppSlice.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [dataRoomApi.reducerPath]: dataRoomApi.reducer,
    [docsApi.reducerPath]: docsApi.reducer,
    [fundingOppsApi.reducerPath]: fundingOppsApi.reducer,
    [loanAppsApi.reducerPath]: loanAppsApi.reducer,
    [loanAppSlice.name]: loanAppSlice.reducer,
    [offersApi.reducerPath]: offersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      authLogoutCleaner.middleware,
      authStateSaver.middleware,
      creditorAppApi.middleware,
      creditorAppStateSaver.middleware,
      countriesApi.middleware,
      customerApi.middleware,
      dataRoomApi.middleware,
      docsApi.middleware,
      fundingOppsApi.middleware,
      loanAppsApi.middleware,
      loanAppStateSaver.middleware,
      offersApi.middleware,
    ]),
});
