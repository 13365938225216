import React from 'react';
import {
  useCreateOfferMutation,
  OfferValuesFormParams,
} from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import { ModalCloseHandler } from '../ModalDialog';
import { AlertType, useAlert } from '../AlertsProvider';
import TermSheetFormModal from './TermSheetFormModal';

type Props = {
  group: DataGroup;
  id: number;
  open: boolean;
  onClose: ModalCloseHandler;
};

function TermSheetCreateModal({ group, id, onClose, ...rest }: Props) {
  const { showAlert } = useAlert();
  const [createOffer, { isLoading }] = useCreateOfferMutation();

  const onCreateOffer = async ({ values }: OfferValuesFormParams) =>
    createOffer({ group, id, values })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Term sheet has been created',
        });
        onClose();
      });

  return (
    <TermSheetFormModal
      onClose={onClose}
      onSubmit={onCreateOffer}
      loading={isLoading}
      title="Create Term Sheet"
      {...rest}
    />
  );
}

export default TermSheetCreateModal;
