import React, { ComponentType, ReactNode } from 'react';
import { SkeletonProps } from '@mui/material/Skeleton';
import DataListSkeletonRow from './DataListSkeletonRow';
import ListSkeleton from '../ListSkeleton';

type Props = {
  children?: ReactNode;
  loading?: boolean;
  rowComponent?: ComponentType<SkeletonProps>;
};

function DataListSkeleton(props: Props) {
  return <ListSkeleton rowComponent={DataListSkeletonRow} gap={5} {...props} />;
}

export default DataListSkeleton;
