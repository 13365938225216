import { Currency } from './types';

export const currencyLabels = {
  [Currency.Eur]: 'Euro',
  [Currency.Gbp]: 'Pound Sterling',
  [Currency.Usd]: 'US Dollar',
  [Currency.Chf]: 'Swiss Franc',
  [Currency.Cad]: 'Canadian Dollar',
  [Currency.Aud]: 'Australian Dollar',
};
