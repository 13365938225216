import React, { createContext, ReactNode, useMemo } from 'react';
import { DataGroup } from 'src/entities/dicts';
import { DataRoomItem } from 'src/entities/dataRoom';
import { useModal } from 'src/hooks';
import FilesDataRoomDownloadModal from './FilesDataRoomDownloadModal';
import ManageFolderModal from './ManageFolderModal';
import FilesDataRoomMenu from './FilesDataRoomMenu';
import FilesDataRoomUploadModal from './FilesDataRoomUploadModal';
import DeleteFileModal from './DeleteFileModal';

type Props = {
  children: ReactNode;
  group: DataGroup;
  id: number;
  canUpdate: boolean;
};

type ContextProps = {
  showDownloadFilesModal: (items: string | string[]) => void;
  showCreateFolderModal: (parentId: string | undefined) => void;
  showUploadFilesModal: (parentId: string | undefined) => void;
  showUploadFolderModal: (parentId: string | undefined) => void;
  showDeleteFileModal: (
    documentId: string | undefined,
    documentName: ReactNode | undefined,
  ) => void;
  handleMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    item: DataRoomItem,
  ) => null;
  group: DataGroup;
  canUpdate: boolean;
};

export const FilesDataRoomContext = createContext<ContextProps>({
  showDownloadFilesModal: () => null,
  showCreateFolderModal: () => null,
  showUploadFilesModal: () => null,
  showUploadFolderModal: () => null,
  showDeleteFileModal: () => null,
  handleMenuClick: () => null,
  group: DataGroup.loanApps,
  canUpdate: false,
});

function FilesDataRoomContextProvider({ children, ...params }: Props) {
  const { id, group, canUpdate, ...restParams } = params;
  const loanApplicationId = id;
  const [currentDataRoomItem, setCurrentDataRoomItem] =
    React.useState<DataRoomItem | null>(null);

  const [anchorComponent, setAnchorComponent] = React.useState<
    null | HTMLElement | undefined
  >(null);
  const IsOpenedDataRoomMenu = Boolean(anchorComponent);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: DataRoomItem,
  ) => {
    event.stopPropagation();
    setAnchorComponent(event.currentTarget);
    setCurrentDataRoomItem(item);
    return null;
  };
  const closeMenu = () => {
    setAnchorComponent(null);
  };

  const [downloadModal, showDownloadModal] = useModal(
    FilesDataRoomDownloadModal,
    params,
  );
  const [createFolderModal, showCreateFolderModal] = useModal(
    ManageFolderModal,
    { loanApplicationId, group, ...restParams },
  );
  const [uploadFilesModal, showUploadFilesModal] = useModal(
    FilesDataRoomUploadModal,
    {
      loanApplicationId,
      uploadFolder: false,
      group,
      ...restParams,
    },
  );
  const [uploadFolderModal, showUploadFolderModal] = useModal(
    FilesDataRoomUploadModal,
    {
      loanApplicationId,
      uploadFolder: true,
      group,
      ...restParams,
    },
  );
  const [deleteFileModal, showDeleteFileModal] = useModal(DeleteFileModal, {
    group,
    loanApplicationId,
  });
  const value = useMemo<ContextProps>(
    () => ({
      showDownloadFilesModal: (items) => showDownloadModal({ items }),
      showCreateFolderModal: (parentId) => showCreateFolderModal({ parentId }),
      showUploadFilesModal: (parentId) => showUploadFilesModal({ parentId }),
      showUploadFolderModal: (parentId) => showUploadFolderModal({ parentId }),
      showDeleteFileModal: (documentId, documentName) =>
        showDeleteFileModal({ documentId, documentName }),
      handleMenuClick,
      group,
      canUpdate,
    }),
    [
      showDownloadModal,
      showCreateFolderModal,
      showUploadFilesModal,
      showUploadFolderModal,
      showDeleteFileModal,
      group,
      canUpdate,
    ],
  );

  return (
    <FilesDataRoomContext.Provider value={value}>
      {children}
      {downloadModal}
      {createFolderModal}
      {uploadFilesModal}
      {uploadFolderModal}
      {deleteFileModal}
      <FilesDataRoomMenu
        open={IsOpenedDataRoomMenu}
        closeMenu={closeMenu}
        currentDataRoomItem={currentDataRoomItem}
        anchorComponent={anchorComponent}
        canUpdate={canUpdate}
      />
    </FilesDataRoomContext.Provider>
  );
}

export default FilesDataRoomContextProvider;
