import React from 'react';
import { useGetOffersListQuery } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import TermSheetItems from './TermSheetItems';
import TermSheetContextContextProvider from './TermSheetContext';

type Props = {
  group: DataGroup;
  id: number;
};

function TermSheet(params: Props) {
  const { group } = params;
  const { data, isFetching } = useGetOffersListQuery(params);

  return (
    <TermSheetContextContextProvider {...params}>
      <TermSheetItems group={group} items={data} loading={isFetching} />
    </TermSheetContextContextProvider>
  );
}

export default TermSheet;
