import React, { createContext, ReactNode, useMemo } from 'react';
import { useModal } from 'src/hooks';
import { OfferItem } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import TermSheetCreateModal from './TermSheetCreateModal';
import TermSheetCounterOfferModal from './TermSheetCounterOfferModal';
import TermSheetDetailsModal from './TermSheetDetailsModal';
import TermSheetAcceptOfferModal from './TermSheetAcceptOfferModal';
import TermSheetDeleteModal from './TermSheetDeleteModal';
import TermSheetEditModal from './TermSheetEditModal';

type Props = {
  children: ReactNode;
  group: DataGroup;
  id: number;
};

type ContextProps = {
  showOfferDetailsModal: (item: OfferItem) => void;
  showCreateOfferModal: () => void;
  showCounterOfferModal: (item: OfferItem) => void;
  showAcceptOfferModal: (id: number) => void;
  showDeleteOfferModal: (id: number) => void;
  showEditOfferModal: (item: OfferItem) => void;
};

export const TermSheetContext = createContext<ContextProps>({
  showOfferDetailsModal: () => null,
  showCreateOfferModal: () => null,
  showCounterOfferModal: () => null,
  showAcceptOfferModal: () => null,
  showDeleteOfferModal: () => null,
  showEditOfferModal: () => null,
});

function TermSheetContextContextProvider({ group, id, children }: Props) {
  const [createOfferModal, showCreateOfferModal] =
    useModal(TermSheetCreateModal);
  const [counterOfferModal, showCounterOfferModal] = useModal(
    TermSheetCounterOfferModal,
  );
  const [acceptOfferModal, showAcceptOfferModal] = useModal(
    TermSheetAcceptOfferModal,
  );
  const [deleteOfferModal, showDeleteOfferModal] =
    useModal(TermSheetDeleteModal);
  const [editOfferModal, showEditOfferModal] = useModal(TermSheetEditModal);
  const [offerDetailsModal, showOfferDetailsModal] = useModal(
    TermSheetDetailsModal,
  );

  const value = useMemo<ContextProps>(
    () => ({
      showOfferDetailsModal: (item) => showOfferDetailsModal({ group, item }),
      showCreateOfferModal: () => showCreateOfferModal({ group, id }),
      showCounterOfferModal: ({ id: termSheetId, ...params }) =>
        showCounterOfferModal({ group, id, termSheetId, ...params }),
      showAcceptOfferModal: (termSheetId) =>
        showAcceptOfferModal({ group, id, termSheetId }),
      showDeleteOfferModal: (termSheetId) =>
        showDeleteOfferModal({ group, id, termSheetId }),
      showEditOfferModal: ({ id: termSheetId, values }) =>
        showEditOfferModal({ group, id, termSheetId, values }),
    }),
    [
      group,
      id,
      showOfferDetailsModal,
      showCreateOfferModal,
      showCounterOfferModal,
      showAcceptOfferModal,
      showDeleteOfferModal,
      showEditOfferModal,
    ],
  );

  return (
    <TermSheetContext.Provider value={value}>
      {children}
      {offerDetailsModal}
      {createOfferModal}
      {counterOfferModal}
      {acceptOfferModal}
      {deleteOfferModal}
      {editOfferModal}
    </TermSheetContext.Provider>
  );
}

export default TermSheetContextContextProvider;
