import React from 'react';
import {
  TreeItem,
  TreeItemProps as MuiTreeItemProps,
} from '@mui/x-tree-view/TreeItem';
import { TreeItemProps, TreeViewItem } from './types';

type Props<T> = {
  data?: T[];
  itemProps?: TreeItemProps<T>;
};

function TreeViewList<T extends TreeViewItem>({
  data = [],
  itemProps,
}: Props<T>) {
  return (
    <>
      {data.map(({ id, name, children, ...rest }) => (
        <TreeItem
          {...(itemProps as MuiTreeItemProps)}
          key={id}
          itemId={id}
          label={name}
          ContentProps={{ id, ...rest }}
        >
          {children ? (
            <TreeViewList data={children as T[]} itemProps={itemProps} />
          ) : null}
        </TreeItem>
      ))}
    </>
  );
}

export default TreeViewList;
